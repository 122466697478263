<script setup lang="ts"></script>

<template>
  <div>
    <!--    <LandingBanner />-->
    <slot />
    <!--    <LazyLandingModal />-->
  </div>
</template>

<style scoped></style>
